import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Colin', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: 'I am a Front End Developer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Colin',
  subtitle: 'I am a Front End Developer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'An experienced Developer specialised in delivering Front end solutions, with expertise across numerous Sass environments (e.g. CMS and ERP systems) including NetSuite, Shopify and BigCommerce. I have a passion for solving complex problems by crafting innovative solutions which enable better business. Working with reputable clients in Asia-Pacific, I have designed and implemented solutions which have positively impacted thousands of end users.',
  paragraphTwo: 'Skills',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.png',
    title: 'Headless Ecommerce with NextJS',
    info: 'A headless e-commerce solution built with NextJS, Shopify GraphQL API and Tailwind CSS',
    info2: '',
    url: 'https://shopify-next-js-tailwind-orpin.vercel.app/',
    repo: '', // if no repo, the button will not show up// if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'Headless Ecommerce with React',
    info: 'A headless e-commerce solution built with React, Shopify Storefront API and Chakra UI',
    info2: '',
    url: 'https://headless-shopify-react.vercel.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: 'A Crypto Website with React (Coming Soon)',
    info: 'A cryptocurrency website providing users with prices, charts market cap data using React and CoinGecko Crypto API',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },

];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
// export const footerData = {
//   networks: [
//     {
//       id: nanoid(),
//       name: 'twitter',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'codepen',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'linkedin',
//       url: '',
//     },
//     {
//       id: nanoid(),
//       name: 'github',
//       url: '',
//     },
//   ],
// };

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
